const xmlData = `<?xml version="1.0"?>

<ruleset name="Codacy Generated Rules File"
    xmlns="http://pmd.sourceforge.net/ruleset/2.0.0"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://pmd.sourceforge.net/ruleset/2.0.0 https://pmd.sourceforge.io/ruleset_2_0_0.xsd">

    <description>
    Codacy Generated Rules File
    </description>


    <!-- Your rules will come here -->

</ruleset>`

export default xmlData;