import React from "react";
import { Route, withRouter } from "react-router-dom";

import CodacyApi from '../../connectors/codacy-api'

import './CodePatterns.css'

import { Panel, Button, Input, ScrollableContent, IconAndText, Link } from "@codacy/ui-components"


class CodePatterns extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tools: [],
            chosenTool: null,
            codePatterns: [],
            nextCursor: '',
            prevCursor: '',
            limit: 0,
            total: 0
        }
        this.fetchTools = this.fetchTools.bind(this)
        this.pickTool = this.pickTool.bind(this)
        this.fetchPatterns = this.fetchPatterns.bind(this)
    }

    componentDidMount() {
        this.fetchTools()
    }

    fetchTools() {
        CodacyApi.fetchTools().then(tools => { this.setState({ tools }) })
    }

    pickTool(tool) {
        this.setState({
            chosenTool: tool,
            cursor: ''
        }, () => this.fetchPatterns())
    }

    fetchPatterns(cursor) {
        if (this.state.chosenTool !== null) {
            CodacyApi.fetchToolPatterns(this.state.chosenTool.uuid, cursor).then(codePatterns => {
                this.setState({
                    codePatterns: codePatterns.data,
                    nextCursor: codePatterns.pagination.cursor !== undefined ? `cursor=${codePatterns.pagination.cursor}` : '',
                    limit: codePatterns.pagination.limit,
                    total: codePatterns.pagination.total
                })
            })
        }
    }




    render() {
        return (
            <React.Fragment>
                <h1>Codacy Available Tools and Code Patterns</h1>
                <Panel as="section" flexGrow={1} display="flex" flexDirection="row" id={1} >
                    <Panel className="toolList">
                        <h2>Tools</h2>
                        {this.state.tools.map(tool => {
                            return <div><Link onClick={() => this.pickTool(tool)}>{tool.name}</Link></div>
                        })}
                    </Panel>
                    <div className="toolDetails">
                        {this.state.chosenTool !== null ?
                            <Panel>
                                <h2>{this.state.chosenTool.name}</h2>
                                <p>
                                    <span className="toolFeature"><span>Languages:</span> {this.state.chosenTool.languages.join(', ')}</span>
                                    <span className="toolFeature"><span>Is Client Side?</span> {this.state.chosenTool.clientSide ? "Yes" : "No"}</span>
                                    <span className="toolFeature"><span>Configurable?</span> {this.state.chosenTool.configurable ? "Yes" : "No"}</span>
                                </p>
                                <p>
                                    {this.state.chosenTool.description}
                                </p>

                                <h3>Code Patterns</h3>
                                <div className="codePatternsList">
                                    {this.state.codePatterns.map(pattern => {
                                        return <Panel>
                                            <h4>{pattern.title}</h4>
                                            <p>{pattern.description}</p>
                                            <p>
                                                {pattern.explanation}
                                            </p>
                                            <p>
                                                <span className="toolFeature"><span>Category:</span> {pattern.category}</span>
                                                <span className="toolFeature"><span>Level:</span> {pattern.level}</span>
                                                <span className="toolFeature"><span>Severity:</span> {pattern.severityLevel}</span>
                                            </p>
                                        </Panel>
                                    })}
                                </div>
                                {this.state.total > this.state.limit ?
                                    <div>
                                        <Link onClick={() => this.fetchPatterns('')}>Back to first page</Link>

                                        {
                                            this.state.nextCursor !== '' ? <Link className="next" onClick={() => this.fetchPatterns(this.state.nextCursor)}>Next</Link> : null
                                        }
                                    </div> : null
                                }

                            </Panel> : null
                        }


                    </div>
                </Panel>
            </React.Fragment>
        )
    }

}

export default withRouter(CodePatterns)