import React from "react";
import { Route, withRouter } from "react-router-dom";

import CodacyApi from '../../connectors/codacy-api'

import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font, Modal } from '@react-pdf/renderer';


import './AuditReport.css'


import { Panel, Button, Input, ScrollableContent, IconAndText, Link } from "@codacy/ui-components"


import { ReactComponent as GithubIcon } from 'ionicons/dist/ionicons/svg/logo-github.svg'
import { ReactComponent as GitlabIcon } from 'ionicons/dist/ionicons/svg/logo-gitlab.svg'
import { ReactComponent as BitbucketIcon } from 'ionicons/dist/ionicons/svg/logo-bitbucket.svg'

import CodacyLogo from './img/codacy-logo.png';

import ClearSansFont400 from "@fontsource/clear-sans/files/clear-sans-all-400-normal.woff";
import ClearSansFont700 from "@fontsource/clear-sans/files/clear-sans-all-700-normal.woff";




class AuditReport extends React.Component {


    //TODO: move this to a common zone
    providers = {
        'github': { shortname: 'gh', icon: GithubIcon },
        'gitlab': { shortname: 'gl', icon: GitlabIcon },
        'bitbucket': { shortname: 'bb', icon: BitbucketIcon }

    }

    constructor(props) {
        super(props)
        this.state = {
            step: 1,
            token: '',
            providersList: [],
            orgsList: [],
            reposList: [],
            provider: '',
            organization: '',
            repositoryName: '',
            repo: null,
            issuesOverview: [],
            pullRequests: [],
            issues: [],
            issuesPerPattern: [],
            reportStepsReady: 0
        }

        this.handleTokenChange = this.handleTokenChange.bind(this);
        this.setStep = this.setStep.bind(this)
        this.fetchProviders = this.fetchProviders.bind(this)
        this.fetchRepos = this.fetchRepos.bind(this)
        this.reset = this.reset.bind(this)
    }

    reset(){
        this.setState({
            step: 1,
            providersList: [],
            orgsList: [],
            reposList: [],
            provider: '',
            organization: '',
            repositoryName: '',
            repo: null,
            issuesOverview: [],
            pullRequests: [],
            issues: [],
            issuesPerPattern: [],
            reportStepsReady: 0
        })
    }

    handleTokenChange(event) {
        this.setState({ token: event.target.value });
    }

    setStep(step) {
        if (step === 2) {
            CodacyApi.setToken(this.state.token)
            this.fetchProviders()
        }
        this.setState({
            step
        })
    }

    async fetchProviders() {
        let data = await CodacyApi.fetchProviders()
        this.setState({
            providersList: data.map(a => a.provider)
        })
    }

    async fetchOrgs(provider) {
        let data = await CodacyApi.fetchOrganizations(this.providers[provider].shortname)
        this.setState({
            orgsList: data.map(a => a)
        })
    }

    generateRepoLevelReport = (provider, remoteOrganizationName, repositoryName) => {


        Font.register({ family: "clear-sans", src: ClearSansFont400, fontWeight: 400 })
        Font.register({ family: "clear-sans-700", src: ClearSansFont700, fontWeight: 700 })

        const styles = StyleSheet.create({
            page: {
                flexDirection: 'column',
                fontSize: 14,
                marginLeft: 20,
                marginRight: 20,
                fontFamily: "clear-sans"
            },
            section: {
                margin: 10,
                padding: 10,
                flexGrow: 1
            },

            coverHeader: {
                fontSize: 36,
                paddingTop: 20

            },
            coverLogo: {
                alignSelf: "center",
                width: 300,
                paddingTop: 50
            },
            coverTitle: {
                paddingTop: 50,
                fontSize: 36,
                alignSelf: "center"
            },
            pageTitle: {
                paddingTop: 30,
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "clear-sans-700"
            },

            paragraphTitle: {
                fontSize: 18,
            },

            bold: {
                fontWeight: "700",
                fontFamily: "clear-sans-700",
                flexDirection: "row"
            }
        });




        const MyDocument = () => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.coverHeader}>
                        <Text>Static Code Analysis Audit Report</Text>
                    </View>
                    <View>
                        <Image style={styles.coverLogo} src={CodacyLogo} />
                        <Text style={styles.coverTitle}>Codacy</Text>
                    </View>
                </Page>
                <Page style={styles.page} size="A4">
                    <View>
                        <Text style={styles.pageTitle}>Overall</Text>
                    </View>
                    <View>
                        <Text>
                            This Static Code Analysis report is refers to the repository {this.state.repo?.data.repository.name} owned by organization {this.state.repo?.data.repository.owner} on {this.state.repo?.data.repository.provider}.
                        </Text>
                        <Text>
                            The report is based on the selected main branch ('{this.state.repo?.data.selectedBranch.name}') which was last updated on {this.state.repo?.data.selectedBranch.lastUpdated}
                        </Text>
                    </View>

                    <View>
                        <Text><Text style={styles.bold}>Grade:</Text>{this.state.repo?.data.gradeLetter}</Text>
                        <Text><Text style={styles.bold}>Languages on repository:</Text> {this.state.repo?.data.repository.languages.join(', ')}</Text>
                        <Text><Text style={styles.bold}>Total number of Issues:</Text> {this.state.issuesOverview.reduce((a, b) => a + (b['totalResults'] || 0), 0)}</Text>
                        <Text><Text style={styles.bold}>Complex files percentage:</Text> {this.state.repo?.data.issuesPercentage}</Text>
                        <Text><Text style={styles.bold}>Duplication percentage:</Text> {this.state.repo?.data.duplicationPercentage}</Text>
                        <Text style={styles.paragraphTitle}>Coverage</Text>
                        <Text><Text style={styles.bold}>Total Files:</Text> {this.state.repo?.data.coverage.numberTotalFiles}</Text>
                        <Text><Text style={styles.bold}>Uncovered Files:</Text> {this.state.repo?.data.coverage.filesUncovered}</Text>
                        <Text><Text style={styles.bold}>Files with Low Coverage:</Text> {this.state.repo?.data.coverage.filesWithLowCoverage}</Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View>
                        <Text style={styles.pageTitle}>Open Pull Requests</Text>
                    </View>
                    <View>
                        {this.state.pullRequests.map((pr, i) => {
                            return (<Text key={i}><Text style={styles.bold}>{pr.pullRequest.title} {pr.pullRequest.isUpToStandards ? ' Up to Standards' : ' Not up to Standards'}</Text></Text>)
                        })}

                    </View>
                </Page>
                <Page style={styles.page}>
                    <View>
                        <Text style={styles.pageTitle}>Issues per Category</Text>
                        {
                            this.state.issuesOverview.map((issueType, k) => {
                                return (<Text key={k}><Text style={styles.bold}>{issueType.category.name}: {issueType.totalResults}</Text></Text>)
                            })
                        }
                    </View>

                </Page>









            </Document>
        );

        return (<PDFViewer width="100%" height="500">
            <MyDocument />
        </PDFViewer>)
    }

    async generateDoc(repositoryName) {
        this.setState({
            repositoryName: repositoryName
        })

        // Repository data
        CodacyApi.fetchRepo(this.state.provider.shortname, this.state.organization, repositoryName).then(repo => {
            this.setState((prevState, props) => ({
                repo,
                reportStepsReady: prevState.reportStepsReady + 1
            }))
        })

        CodacyApi.fetchIssuesCategoriesOverview(this.state.provider.shortname, this.state.organization, repositoryName)
            .then(issuesOverview => {
                console.log(issuesOverview)
                this.setState((prevState, props) => ({ issuesOverview, reportStepsReady: prevState.reportStepsReady + 1 }))
            })
        CodacyApi.fetchPullRequests(this.state.provider.shortname, this.state.organization, repositoryName).then(pullRequests => {
            this.setState(
                (prevState, props) => ({ pullRequests: pullRequests, reportStepsReady: prevState.reportStepsReady + 1 }))
        })
        // CodacyApi.fetchIssues(this.state.provider.shortname, this.state.organization, repositoryName).then(issues => {
        //     this.setState({
        //         issues,
        //         issuesPerPattern: issues.reduce(function (rv, x) {
        //             (rv[x['patternInfo']['id']] = rv[x['patternInfo']['id']] || []).push(x);
        //             return rv;
        //         }, {}),
        //         reportStepsReady: this.state.reportStepsReady + 1

        //     })

        // }
        // )

        this.setStep(3)
    }

    async fetchRepos(provider, organization) {
        let data = await CodacyApi.fetchRepositories(provider, organization)
        this.setState({
            reposList: data.map(a => a),
            organization: organization
        })
    }

    step1() {
        return (
            <React.Fragment>
                <h1>Codacy Audit Report</h1>
                <Panel as="section" flexGrow={1} display="flex" flexDirection="row" id={1} >

                    <Panel className="select-list" display="flex" flexDirection="column">
                        <h3>
                            Please fill your Codacy API Token
                        </h3>
                        <div>
                            <Input type="text" value={this.state.token} onChange={this.handleTokenChange} />
                        </div>
                        <div display="flex" flexDirection="column">
                            <Button onClick={() => this.setStep(2)}>Next</Button>
                            <Button onClick={() => this.reset()}>Reset</Button>
                        </div>
                    </Panel>
                    <Panel className="select-list">
                        <div>
                            <h3>Provider</h3>
                            <div>
                                {
                                    this.state.providersList.map((p, k) => {
                                        return (<div key={k}><Link onClick={() => { this.setState({ provider: this.providers[p] }); this.fetchOrgs(p) }} key={p}>
                                            <IconAndText icon={this.providers[p].icon} /> {p}
                                        </Link></div>)
                                    })
                                }
                            </div>
                        </div>
                    </Panel>
                    <Panel className="select-list">
                        <div>
                            <h3>Organization</h3>
                            <div>
                                {
                                    this.state.orgsList.map((p, k) => {
                                        return (<div key={k}><Link onClick={() => this.fetchRepos(p.provider, p.name)} key={p.id}>{p.name}</Link></div>)
                                    })
                                }
                            </div>
                        </div>
                    </Panel>
                    <Panel className="select-list">
                        <div>
                            <h3>Repository</h3>
                            <ScrollableContent maxHeight="15rem" overflow="hidden" py={2}>
                                {
                                    this.state.reposList.map((p, k) => {
                                        return (<div key={k}><Link onClick={() => this.generateDoc(p.name)} key={p.id}>{p.name}</Link></div>)
                                    })
                                }
                            </ScrollableContent>
                        </div>
                    </Panel>
                </Panel>
            </React.Fragment>
        )
    }


    render() {
        return (
            <React.Fragment>
                {this.step1()}
                <Panel>
                    {this.state.reportStepsReady === 3 ? this.generateRepoLevelReport() : null}
                </Panel>
            </React.Fragment>
        )
    }
}

export default withRouter(AuditReport)