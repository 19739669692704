import logo from './logo.svg';
import './App.css';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { ThemeProvider, LayoutColumn } from '@codacy/ui-components'
import IconNav from '@codacy/ui-components'


import React from 'react';

import { Helmet } from 'react-helmet'
import routes from "./routes"


function mapRoutes(routes) {
  return routes.map((prop, key) => {
    return (
      <Route
        path={prop.path}
        component={prop.component}
        key={key}
        exact={prop.exact ? prop.exact : true}
      />
    );

  });
}

const App: React.FC = () => {


  return (
    <>
      <Helmet defer={false}>

      </Helmet>
      <ThemeProvider>
        <div className="header-bar">
          <nav>
            <a href="/">
              <div className="logo-container">
                <img className="logo" src="/media/codacy-logo.svg" />
              </div>
            </a>
          </nav>
          <div className="header-bar-right">
            <div className="header-bar-right-item">
              <a href="https://app.codacy.com" target="_blank">Go to Codacy</a>
            </div>
          </div>
        </div>

        <Switch>
          {mapRoutes(routes.routes)}
        </Switch>
      </ThemeProvider>
    </>
  );
}

export default App;
