import { ReactComponent as GithubIcon } from 'ionicons/dist/ionicons/svg/logo-github.svg'
import { ReactComponent as GitlabIcon } from 'ionicons/dist/ionicons/svg/logo-gitlab.svg'
import { ReactComponent as BitbucketIcon } from 'ionicons/dist/ionicons/svg/logo-bitbucket.svg'

const axios = require('axios').default

const baseurl = 'https://app.codacy.com/api/v3'

const CodacyApi = {


    providers: () => {
        return {
            'github': { shortname: 'gh', icon: GithubIcon },
            'gitlab': { shortname: 'gl', icon: GitlabIcon },
            'bitbucket': { shortname: 'bb', icon: BitbucketIcon }
    
        }
    },

    setToken: (token) => {
        axios.interceptors.request.use(
            function (config) {
                config.headers['api-token'] = token
                config.headers['Access-Control-Allow-Origin'] = '*'
                config.headers['referrer-policy'] = 'origin-when-cross-origin, strict-origin-when-cross-origin'
                config.headers['content-type'] = 'application/json'
                config.headers['csrf-token']= 'nocheck'
                config.headers['X-Requested-With'] = 'XMLHttpRequest'

                return config
            },
            function (error) {
                return Promise.reject(error)
            }
        )
    },


    fetchRepo: async (provider, remoteOrganizationName, repositoryName) => {
        let url = `${baseurl}/analysis/organizations/${provider}/${remoteOrganizationName}/repositories/${repositoryName}`
        const response = (await axios.get(url)).data
        return response
    },


    fetchIssues: async (provider, remoteOrganizationName, repositoryName) => {
        let cursor = 1
        const limit = 1000
        let hasNextPage = true
        let issues = []

        while (hasNextPage) {
            const url = `${baseurl}/analysis/organizations/${provider}/${remoteOrganizationName}/repositories/${repositoryName}/issues/search?cursor=${cursor}&limit=${limit}`
            const response = (await axios.post(url, {})).data
            issues = issues.concat(response.data)
            if (response.pagination.hasOwnProperty('cursor')) {
                cursor = response.pagination.cursor
            } else {
                hasNextPage = false
            }

        }
        return issues
    },


    fetchPullRequests: async (provider, remoteOrganizationName, repositoryName) => {
        const url = `${baseurl}/analysis/organizations/${provider}/${remoteOrganizationName}/repositories/${repositoryName}/pull-requests`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchOrganizationPullRequests: async (provider, remoteOrganizationName,search) => {
        const url = `${baseurl}/analysis/organizations/${provider}/${remoteOrganizationName}/pull-requests`
        const response = (await axios.get(url)).data
        return response.data
    },


    fetchIssuesCategoriesOverview: async (provider, remoteOrganizationName, repositoryName) => {
        const url = `${baseurl}/analysis/organizations/${provider}/${remoteOrganizationName}/repositories/${repositoryName}/category-overviews`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchProviders: async () => {
        const url = `${baseurl}/provider/integrations`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchOrganizations: async (provider) => {
        const url = `${baseurl}/user/organizations/${provider}`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchRepositories: async (provider, organization) => {
        const url = `${baseurl}/organizations/${provider}/${organization}/repositories`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchCodingStandards: async (provider, organization) => {
        const url = `${baseurl}/organizations/${provider}/${organization}/coding-standards`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchCodingStandardsPatternsForTool: async (provider, organization,codingStandardId,toolUuid) => {
        const url = `${baseurl}/organizations/${provider}/${organization}/coding-standards/${codingStandardId}/tools/${toolUuid}/patterns?limit=1000`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchTools: async () => {
        const url = `${baseurl}/tools`
        const response = (await axios.get(url)).data
        return response.data
    },

    fetchToolPatterns: async (toolUuid,cursor) => {
        const url = `${baseurl}/tools/${toolUuid}/patterns?${cursor}`
        const response = (await axios.get(url)).data
        return response
    }


}

export default CodacyApi