import React from "react";
import { Route, withRouter } from "react-router-dom";

import CodacyApi from '../../connectors/codacy-api'


class VanityMetrics extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            token: '',
            provider: 'gh',
            organization: 'prodsmart'
        }

        
        this.fetchOrganizationPullRequests = this.fetchOrganizationPullRequests.bind(this)
    }

    componentDidMount() {
        
    }


    fetchOrganizationPullRequests() {
        CodacyApi.fetchOrganizationPullRequests(this.state.provider, this.state.organization, 'last-updated=2018-01-01')
            .then((data, err) => {
                if (err) {
                    //TODO: do some stuff
                    return
                }
                console.log(data)
            })

        CodacyApi.fetchRepositories(this.state.provider, this.state.organization).then(repos => {
            repos.forEach(repo => {
                CodacyApi.fetchPullRequests(this.state.provider, this.state.organization, repo.name).then(prs => console.log(prs))
            }
            )
        }
        )
    }



    render() {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}


export default withRouter(VanityMetrics)