import ExperimentList from './experiments/ExperimentList'
import AuditReport from './experiments/audit-report/AuditReport'
import VanityMetrics from './experiments/vanity-metrics/VanityMetrics'
import CodePatterns from './experiments/code-patterns/CodePatterns'
import ConfigFileGenerator from './experiments/config-file-generator/ConfigFileGenerator'

export default {routes: [
    {
        path: "/",
        component: ExperimentList,
        exact: true,
        listed: false
    },
    {
        path: "/audit-report",
        title: "Audit Report",
        description: "A Static Code Analysis Audit Report",
        component: AuditReport,
        exact: true,
        listed: true
    },
    {
        path: "/code-patterns",
        title: "Tools Code Patterns",
        description: "List of Tools and Code Patterns",
        component: CodePatterns,
        exact: true,
        listed: true
    },
    {
        path: "/vanity-metrics",
        title: "Vanity Metrics",
        description: "Metrics that showcase Codacy Value",
        component: VanityMetrics,
        exact: true,
        listed: false
    },
    {
        path: "/config-file-generator",
        title: "Config File Generator",
        description: "Generates a config file from UI config (Only PMD and Checkstyle)",
        component: ConfigFileGenerator,
        exact: true,
        listed: true
    }
]}
