import React from "react";
import { Route, withRouter } from "react-router-dom";
import './ExperimentList.css'

import {Panel, Button} from "@codacy/ui-components"

import routes from "../routes.js";


class ExperimentList extends React.Component {


    mapRoutes(routes) {
        return routes.filter(r => r.listed).map((prop, key) => {
          return (
            <Panel as="section" flexGrow={1} display="flex" flexDirection="row" id={key} className="report-list-item">
                <div>
                <h1>{prop.title}</h1>
                <p>{prop.description}</p>
                <Button onClick={() => { this.props.history.push(prop.path) }}>Start</Button>
                </div>
            </Panel>
          );
      
        });
      }

    render() {
        return (
            <React.Fragment>
                {this.mapRoutes(routes.routes)}
            </React.Fragment>
        )
    }
}


export default withRouter(ExperimentList)