import React from "react";
import { Route, withRouter } from "react-router-dom";

import CodacyApi from '../../connectors/codacy-api'

import { Panel, Button, Input, ScrollableContent, IconAndText, Link, themeColors } from "@codacy/ui-components"





class ConfigFileGenerator extends React.Component {


    providers = CodacyApi.providers()
    constructor(props) {
        super(props)
        this.state = {
            tools: [],
            chosenTool: null,
            token: '',
            providersList: [],
            orgsList: [],
            codingStandards: [],
            provider: '',
            organization: '',
            fileContent: ''

        }

        this.fetchTools = this.fetchTools.bind(this)
        this.pickTool = this.pickTool.bind(this)
        this.handleTokenChange = this.handleTokenChange.bind(this);
        this.fetchProviders = this.fetchProviders.bind(this)
        this.fetchOrgs = this.fetchOrgs.bind(this)
        this.fetchCodingStandards = this.fetchCodingStandards.bind(this)
        this.generateDoc = this.generateDoc.bind(this)
        this.generateDocPMD = this.generateDocPMD.bind(this)
        this.generateDocCheckstyle = this.generateDocCheckstyle.bind(this)
    }

    componentDidMount() {
        this.fetchTools()
    }

    fetchTools() {
        CodacyApi.fetchTools().then(tools => { this.setState({ tools }) })
    }

    pickTool(tool) {
        this.setState({
            chosenTool: tool
        })
    }

    handleTokenChange(event) {
        this.setState({ token: event.target.value }, () => CodacyApi.setToken(this.state.token));
    }

    fetchProviders() {
        CodacyApi.fetchProviders().then(data => {
            this.setState({
                providersList: data.map(a => a.provider)
            })
        })

    }

    fetchOrgs(provider) {
        CodacyApi.fetchOrganizations(this.providers[provider].shortname)
            .then(data => {
                this.setState({
                    provider,
                    orgsList: data.map(a => a)
                })
            })

    }

    fetchCodingStandards(provider, organization) {
        CodacyApi.fetchCodingStandards(provider, organization)
            .then(data => {
                this.setState({
                    organization,
                    codingStandards: data.map(a => a)
                })
            })
    }

    generateDocPMD(codeStandardsId) {
        CodacyApi.fetchCodingStandardsPatternsForTool(this.providers[this.state.provider].shortname, this.state.organization, codeStandardsId, this.state.chosenTool.uuid)
            .then(data => {
                let xmlData = require('./pmd-template.js').default
                let contentRules = []
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].enabled) {
                        console.log(data[i])
                        continue;
                    }
                    let pattern = data[i].patternDefinition
                    console.log(pattern)
                    //"PMD_category_apex_bestpractices_ApexUnitTestClassShouldHaveAsserts",
                    //<rule ref="category/apex/bestpractices.xml/ApexUnitTestClassShouldHaveAsserts"
                    let patternIdSplitted = pattern.id.split('_')
                    let properties = ''
                    if (pattern.parameters.length > 0) {
                        properties = `
                        <properties>
                            ${data[i].parameters.map(p => {
                            return `<property name="${p.name}">
                                    <value>${p.value}</value>
                                </property>`}).join('\r\n')
                            }
                        </properties>`
                    }
                    let rule = `<rule message="${pattern.description}" ref="${patternIdSplitted[1]}/${patternIdSplitted[2]}/${patternIdSplitted[3]}.xml/${patternIdSplitted.slice(4, patternIdSplitted.length).join("_")}">${properties}</rule>`
                    contentRules.push(rule)
                }

                this.setState({
                    fileContent: xmlData.replace('<!-- Your rules will come here -->', contentRules.join('\r\n'))
                })

            })
    }

    generateDocCheckstyle(codeStandardsId) {
        CodacyApi.fetchCodingStandardsPatternsForTool(this.providers[this.state.provider].shortname, this.state.organization, codeStandardsId, this.state.chosenTool.uuid)
            .then(data => {
                let xmlData = require('./checkstyle-template.js').default
                let parents = require('./CheckstylePatternParents.js').default
                let contentRules = {
                    'Checker': []
                }
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].enabled) {
                        console.log(data[i])
                        continue;
                    }
                    let pattern = data[i].patternDefinition
                    console.log(pattern)
                    let patternIdSplitted = pattern.id.split('_')
                    let patternId = patternIdSplitted.pop()
                    let properties = ''
                    if (pattern.parameters.length > 0) {
                        properties = `
                            ${data[i].parameters.map(p => {
                            return p.value ? `<property name="${p.name}" value="${p.value}" />` : `<property name="${p.name}" value="nothing" />`
                        }).join('\r\n')
                            }`
                    }
                    let rule = `<module name="${patternId}">${properties}</module>`
                    if (patternId in parents) {
                        if (contentRules[parents[patternId]] === undefined) {
                            contentRules[parents[patternId]] = []
                        }
                        contentRules[parents[patternId]].push(rule)
                    } else {
                        contentRules['Checker'].push(rule)
                    }

                }

                let xmlContent = ''
                
                for (const [key, contentRule] of Object.entries(contentRules)) {
                    if (key == 'Checker') {
                        xmlContent += contentRule.join('\r\n')
                    } else {
                        xmlContent += `
                            <module name="${key}">
                                ${contentRule.join('\r\n')}
                            </module>
                        `
                    }
                }

                this.setState({
                    fileContent: xmlData.replace('<!-- Your rules will come here -->', xmlContent)
                })

            })
    }

    generateDoc(codeStandardsId) {
        switch (this.state.chosenTool.name) {
            case 'PMD':
                this.generateDocPMD(codeStandardsId)
                break
            case 'Checkstyle':
                this.generateDocCheckstyle(codeStandardsId)
                break
            default:
                window.alert('Currently only available for PMD and Checkstyle')
                return

        }
    }


    render() {
        return (
            <React.Fragment>
                <h1>Config File Generator</h1>
                <h2>CURRENTLY ONLY FOR PMD AND CHECKSTYLE</h2>
                <Panel as="section" flexGrow={1} display="flex" flexDirection="row" id={1} >
                    <Panel className="toolList">
                        <h2>Tools</h2>
                        {this.state.tools.map(tool => {
                            return <div><Link onClick={() => this.pickTool(tool)}>{tool.name}</Link></div>
                        })}
                    </Panel>
                    <div className="toolDetails">
                        {this.state.chosenTool !== null ?
                            <Panel>
                                <h2>{this.state.chosenTool.name}</h2>
                                <p>
                                    <span className="toolFeature"><span>Languages:</span> {this.state.chosenTool.languages.join(', ')}</span>
                                    <span className="toolFeature"><span>Is Client Side?</span> {this.state.chosenTool.clientSide ? "Yes" : "No"}</span>
                                    <span className="toolFeature"><span>Configurable?</span> {this.state.chosenTool.configurable ? "Yes" : "No"}</span>
                                </p>
                                <p>
                                    {this.state.chosenTool.description}
                                </p>
                                <React.Fragment>
                                    <h2>Fill the details</h2>
                                    <Panel as="section" flexGrow={1} display="flex" flexDirection="row" id={1} >

                                        <Panel className="select-list" display="flex" flexDirection="column">
                                            <h3>
                                                Please fill your Codacy API Token
                                            </h3>
                                            <div>
                                                <Input type="text" value={this.state.token} onChange={this.handleTokenChange} />
                                            </div>
                                            <div display="flex" flexDirection="column">
                                                <Button onClick={() => this.fetchProviders()}>Next</Button>                                            </div>
                                        </Panel>
                                        <Panel className="select-list">
                                            <div>
                                                <h3>Provider</h3>
                                                <div>
                                                    {
                                                        this.state.providersList.map((p, k) => {
                                                            return (<div key={k}><Link onClick={() => { this.setState({ provider: this.providers[p] }); this.fetchOrgs(p) }} key={p}>
                                                                <IconAndText icon={this.providers[p].icon} /> {p}
                                                            </Link></div>)
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Panel>
                                        <Panel className="select-list">
                                            <div>
                                                <h3>Organization</h3>
                                                <div>
                                                    {
                                                        this.state.orgsList.map((p, k) => {
                                                            return (<div key={k}><Link onClick={() => this.fetchCodingStandards(p.provider, p.name)} key={p.id}>{p.name}</Link></div>)
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Panel>
                                        <Panel className="select-list">
                                            <div>
                                                <h3>Coding Standards</h3>
                                                <ScrollableContent maxHeight="15rem" overflow="hidden" py={2}>
                                                    {
                                                        this.state.codingStandards.map((p, k) => {
                                                            return (<div key={k}><Link onClick={() => this.generateDoc(p.id)} key={p.id}>{p.name}</Link></div>)
                                                        })
                                                    }
                                                </ScrollableContent>
                                            </div>
                                        </Panel>
                                    </Panel>
                                    {this.state.fileContent !== '' ?
                                        <Panel>
                                            <h3>Generated File</h3>
                                            <code>
                                                {this.state.fileContent}
                                            </code>
                                        </Panel>
                                        : null
                                    }
                                </React.Fragment>
                            </Panel>
                            : null
                        }
                    </div>
                </Panel>
            </React.Fragment>
        )
    }
}

export default withRouter(ConfigFileGenerator)